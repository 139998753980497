export const sentimentBad = 'Negative'
export const sentimentMeh = 'Neutral'
export const sentimentGood = 'Positive'
export const sentimentUnknown = 'Unknown'

export const icons = {
  add: 'fas fa-plus',
  back: 'fas fa-chevron-circle-left',
  cancel: 'fas fa-ban',
  clear: 'fas fa-times',
  comments: 'far fa-comments',
  contactUs: 'fas fa-headset',
  currency: 'fas fa-dollar-sign',
  dashboard: 'fas fa-border-all',
  delete: 'fas fa-trash',
  edit: 'fas fa-pencil-alt',
  email: 'fas fa-envelope',
  image: 'fas fa-image',
  kpis: 'fas fa-tachometer-alt',
  left: 'fas fa-chevron-left',
  navMenu: 'fas fa-bars',
  no: 'fas fa-ban',
  percent: 'fas fa-percent',
  phone: 'fas fa-phone',
  profile: 'fas fa-user',
  right: 'fas fa-chevron-right',
  save: 'fas fa-save',
  search: 'fas fa-search',
  shop: 'fas fa-car',
  signOut: 'fas fa-sign-out-alt',
  yes: 'fas fa-check',
  undo: 'fas fa-undo',
  url: 'fas fa-globe'
}

export const text = {
  actionNeeded: 'Action Needed',
  add: 'Add',
  address: 'Address',
  all: 'All',
  appName: 'Auto Shop Follow Up',
  appointmentsScheduled: 'Appointments',
  avgRepairOrder: 'Average Repair Order',
  avgRepairTotal: 'Average Repair Total',
  back: 'Back',
  bays: 'Bays',
  callOut: 'Call Out',
  callSummary: 'Call Summary',
  cancel: 'Cancel',
  carCount: 'Car Count',
  city: 'City',
  comments: 'Comments',
  contactUs: 'Contact Us',
  costs: 'Costs',
  crossStreets: 'Cross Streets',
  dash3Months: '3 Months',
  dash6Months: '6 Months',
  dash12Months: '12 Months',
  dashMtd: 'MTD',
  dashYtd: 'YTD',
  dashboard: 'Dashboard',
  dashboards: 'Dashboards',
  date: 'Date',
  day01: 'SUN',
  day02: 'MON',
  day03: 'TUE',
  day04: 'WED',
  day05: 'THU',
  day06: 'FRI',
  day07: 'SAT',
  delete: 'Delete',
  deleteOk: 'Record was deleted successfully!',
  detail: 'Detail',
  discounts: 'Discounts',
  doNotCall: 'Do Not Call',
  effectiveLaborRate: 'Effective Labor Rate',
  email: 'Email',
  employee: 'Employee',
  employeeCostPercent: 'Employee Cost Percent',
  errorGeneric: 'Something went wrong; please contact our team so we can correct the problem',
  escalation: 'Action Needed - Urgent',
  fees: 'Fees',
  financial: 'Financial',
  financials: 'Financials',
  followUp: 'Follow Up',
  followUps: 'Follow Ups',
  grossProfit: 'Gross Profit',
  grossProfitPerHoursBilled: 'Gross Profit Per Hours Billed',
  grossSales: 'Gross Sales',
  grossSalesPerHoursBilled: 'Gross Sales Per Hours Billed',
  headsUp: 'Heads Up',
  hide: 'Hide',
  highlight: 'Highlight',
  homePage: 'Home Page',
  hourlyExpense: 'Hourly Expense',
  hourlyRate: 'Hourly Rate',
  kpis: 'KPIs',
  kpiTargets: 'KPI Targets',
  laborCost: 'Weekly Labor Cost',
  laborDiscounts: 'Labor Discounts',
  laborMargin: 'Labor Margin',
  laborSales: 'Labor Sales',
  logo: 'Logo',
  monthlyExpense: 'Monthly Expense (Non-Wages)',
  name: 'Name',
  negative: 'Negative',
  negativeFeedback: 'Negative Feedback',
  netProfit: 'Net Profit',
  netProfitPerLaborHour: 'Net Profit per Labor Hour',
  neutral: 'Neutral',
  notes: 'Notes',
  opportunities: 'Opportunities',
  partsCost: 'Parts Cost',
  partsDiscounts: 'Parts Discounts',
  partsMargin: 'Parts Margin',
  partSales: 'Part Sales',
  partMixPercent: 'Part Mix Percent',
  positive: 'Positive',
  phone: 'Phone',
  postalCode: 'Zip',
  profile: 'Profile',
  readOnly: 'This value is cannot be changed',
  required: 'Please fill in all required fields',
  reviewsCommitted: 'Reviews Committed',
  result: 'Result',
  results: 'Results',
  sales: 'Sales',
  salesOpportunity: 'Revenue Opportunity',
  save: 'Save',
  saveOk: 'Your changes were saved successfully!',
  scheduledAppointment: 'Appointment Scheduled',
  selectShop: 'Select a shop',
  settings: 'Settings',
  shop: 'Shop',
  shops: 'Shops',
  shopSupplySales: 'Shop Supply Sales',
  show: 'Show',
  search: 'Search',
  serviceAdvisor: 'Service Advisor',
  serviceWriterExpense: 'Service Writer Expense',
  shopOverhead: 'Shop Overhead',
  shopSupplyCOGS: 'Shop Supply COGS',
  signOut: 'Sign Out',
  state: 'State',
  subletCOGS: 'Sublet COGS',
  subletSales: 'Sublet Sales',
  submit: 'Submit',
  targets: 'Targets',
  team: 'Team',
  techPayroll: 'Tech Payroll',
  techCount: 'Technician Count',
  timeSheet: 'Time Sheet',
  timeSheets: 'Time Sheets',
  total: 'Total',
  totalBilledHours: 'Total Billed Hours',
  totalCOGS: 'Total COGS',
  type: 'Type',
  undo: 'Undo',
  workingDays: 'Working Days',
  workingHoursPerDay: 'Working Hours Per Day',
  year: 'Year',
  yes: 'Yes'
}
