import dayjs from 'dayjs'
import { api } from '@/api'
import { Dto as ShopDto } from '@/shops/viewModel'
import {
  aROName,
  billedHours,
  carCount,
  cogsName,
  eLRName,
  gpPerBilled,
  gpPerTSName,
  grossProfit,
  gsPerBilled,
  laborMargin,
  laborSales,
  partCosts,
  partMargin,
  partSales,
  ResultKPI,
  SalesKPI,
  subletCOGS,
  tbeName,
  teName,
  totalSales,
  tsHours
} from '@/kpis/viewModel'

import { secondsToHours, valueFromKey } from '@/utils'

const resource = 'KPIs'

export const kpis = {
  actions: {
    /**
     * @param context {Object} - module instance injected by Vuex
     * @param v { string} - Date ISO string
     */
    dpChanged (context, v) { context.commit('setDP', v) },
    /**
     * @param args {Object}
     * @param args.id {string} - ShopDto ID
     * @param args.tok {string} - Access Token
     * @param context {Object} - module instance injected by Vuex
     * @returns {Promise<void>}
     */
    async fetchKPIs (context, args) {
      const end = dayjs(context.state.dp).weekday(6).toISOString()
      const id = valueFromKey('id', args)
      const start = dayjs(context.state.dp).weekday(0).toISOString()
      const tok = valueFromKey('tok', args)
      const r = await api.get(`/Shops/${id}/${resource}`, tok, { end, start })
      const l = r.map((e) => SalesKPI.fromApi(e))
      context.commit('setKPIs', l)
    },
    /**
     * @param context {any}
     * @param v {ShopDto} */
    setShop (context, v) { context.commit('setShop', v) }
  },
  getters: {
    aRO (s) {
      const r = new ResultKPI()
      const cc = s.kpis.find((e) => e.Name === carCount)
      const ts = s.kpis.find((e) => e.Name === totalSales)
      r.Name = aROName
      r.Day01 = 0
      r.Day02 = 0
      r.Day03 = 0
      r.Day04 = 0
      r.Day05 = 0
      r.Day06 = 0
      r.Day07 = 0
      for (let i = 1; i < 8; i++) {
        let c = 0
        if (cc) c = cc[`Day0${i}`] ?? 0
        let s = 0
        if (ts) s = ts[`Day0${i}`] ?? 0
        let v = 0
        if (c !== 0) v = s / c
        r[`Day0${i}`] = v
      }
      return r
    },
    cogs (s) {
      const r = new ResultKPI()
      r.Name = cogsName
      r.Day01 = 0
      r.Day02 = 0
      r.Day03 = 0
      r.Day04 = 0
      r.Day05 = 0
      r.Day06 = 0
      r.Day07 = 0
      const cp = (s.shop?.EmployeeCostPercent ?? 0) * 0.01
      const lsl = s.kpis.find((e) => e.Name === laborSales)
      const pcl = s.kpis.find((e) => e.Name === partCosts)
      const scl = s.kpis.find((e) => e.Name === subletCOGS)
      const sme = s.shop?.MonthlyExpensesNonSalary ?? 0
      for (let i = 1; i < 8; i++) {
        let lc = 0
        let pc = 0
        let sc = 0
        // labor costs
        if (lsl) {
          const ls = lsl[`Day0${i}`] ?? 0
          lc = ls * cp
        }
        // part costs
        if (pcl) pc = pcl[`Day0${i}`] ?? 0

        // sublet COGS
        if (scl) sc = scl[`Day0${i}`] ?? 0

        r[`Day0${i}`] = lc + pc + sc + sme
      }
      return r
    },
    dp (s) { return s.dp },
    eLR (s, g, rs, rg) {
      const r = new ResultKPI()
      const ls = g.kpis.find((e) => e.Name === laborSales)
      r.Name = eLRName
      r.Day01 = 0
      r.Day02 = 0
      r.Day03 = 0
      r.Day04 = 0
      r.Day05 = 0
      r.Day06 = 0
      r.Day07 = 0
      for (let i = 1; i < 8; i++) {
        const h = rg[`timeSheets/day0${i}Total`] ?? 0
        let s = 0
        if (ls) s = ls[`Day0${i}`] ?? 0
        let v = 0
        if (h !== 0) v = s / h
        r[`Day0${i}`] = v.toFixed(2)
      }
      return r
    },
    gpPerBilled (s, g) {
      const r = new ResultKPI()
      const bh = s.kpis.find((e) => e.Name === billedHours)
      const gp = g.grossProfit
      r.Name = gpPerBilled
      r.Day01 = 0
      r.Day02 = 0
      r.Day03 = 0
      r.Day04 = 0
      r.Day05 = 0
      r.Day06 = 0
      r.Day07 = 0
      for (let i = 1; i < 8; i++) {
        let h = 0
        if (bh) h = parseFloat(secondsToHours(bh[`Day0${i}`] ?? 0))
        let p = 0
        if (gp) p = gp[`Day0${i}`] ?? 0
        let v = 0
        if (h !== 0) v = p / h
        r[`Day0${i}`] = v
      }
      return r
    },
    gpPerTS (s, g) {
      const r = new ResultKPI()
      r.Name = gpPerTSName
      r.Day01 = 0
      r.Day02 = 0
      r.Day03 = 0
      r.Day04 = 0
      r.Day05 = 0
      r.Day06 = 0
      r.Day07 = 0
      for (let i = 1; i < 8; i++) {
        let h = 0
        if (g.tsHours) h = g.tsHours[`Day0${i}`] ?? 0
        let p = 0
        if (g.grossProfit) p = g.grossProfit[`Day0${i}`] ?? 0
        let v = 0
        if (h !== 0) v = p / h
        r[`Day0${i}`] = v
      }
      return r
    },
    grossProfit (s, g) {
      const r = new ResultKPI()
      const cogs = g.cogs
      const ts = s.kpis.find((e) => e.Name === totalSales)
      r.Name = grossProfit
      r.Day01 = 0
      r.Day02 = 0
      r.Day03 = 0
      r.Day04 = 0
      r.Day05 = 0
      r.Day06 = 0
      r.Day07 = 0
      for (let i = 1; i < 8; i++) {
        let c = 0
        if (cogs) c = cogs[`Day0${i}`] ?? 0
        let s = 0
        if (ts) s = ts[`Day0${i}`] ?? 0
        r[`Day0${i}`] = s - c
      }
      return r
    },
    gsPerBilled (s) {
      const r = new ResultKPI()
      const bh = s.kpis.find((e) => e.Name === billedHours)
      const ts = s.kpis.find((e) => e.Name === totalSales)
      r.Name = gsPerBilled
      r.Day01 = 0
      r.Day02 = 0
      r.Day03 = 0
      r.Day04 = 0
      r.Day05 = 0
      r.Day06 = 0
      r.Day07 = 0
      for (let i = 1; i < 8; i++) {
        let h = 0
        if (bh) h = parseFloat(secondsToHours(bh[`Day0${i}`] ?? 0))
        let s = 0
        if (ts) s = ts[`Day0${i}`] ?? 0
        let v = 0
        if (h !== 0) v = s / h
        r[`Day0${i}`] = v
      }
      return r
    },
    kpis (s) { return s.kpis },
    laborMargin (s) {
      const r = new ResultKPI()
      const cp = (s.shop?.EmployeeCostPercent ?? 0) * 0.01
      const ls = s.kpis.find((e) => e.Name === laborSales)
      r.Name = laborMargin
      r.Day01 = 0
      r.Day02 = 0
      r.Day03 = 0
      r.Day04 = 0
      r.Day05 = 0
      r.Day06 = 0
      r.Day07 = 0
      if (ls) {
        for (let i = 1; i < 8; i++) {
          const s = ls[`Day0${i}`]
          const c = s * cp
          let m = 0
          if (s > 0) {
            m = (s - c) / s
          }
          r[`Day0${i}`] = m
        }
      }
      return r
    },
    partsMargin (s) {
      const r = new ResultKPI()
      const pc = s.kpis.find((e) => e.Name === partCosts)
      const ps = s.kpis.find((e) => e.Name === partSales)
      r.Name = partMargin
      r.Day01 = 0
      r.Day02 = 0
      r.Day03 = 0
      r.Day04 = 0
      r.Day05 = 0
      r.Day06 = 0
      r.Day07 = 0
      if (pc && ps) {
        for (let i = 1; i < 8; i++) {
          const c = pc[`Day0${i}`]
          const s = ps[`Day0${i}`]
          let m = 0
          if (s > 0) {
            m = (s - c) / s
          }
          r[`Day0${i}`] = m
        }
      }
      return r
    },
    shop (s) { return s.shop },
    tbe (s, g) {
      const r = new ResultKPI()
      r.Name = tbeName
      r.Day01 = 0
      r.Day02 = 0
      r.Day03 = 0
      r.Day04 = 0
      r.Day05 = 0
      r.Day06 = 0
      r.Day07 = 0
      const th = (s.shop.Bays ?? 0) * (s.shop.WorkingHoursPerDay ?? 0)
      if (th !== 0) {
        r.Day01 = (g.tsHours.Day01 ?? 0) / th
        r.Day02 = (g.tsHours.Day02 ?? 0) / th
        r.Day03 = (g.tsHours.Day03 ?? 0) / th
        r.Day04 = (g.tsHours.Day04 ?? 0) / th
        r.Day05 = (g.tsHours.Day05 ?? 0) / th
        r.Day06 = (g.tsHours.Day06 ?? 0) / th
        r.Day07 = (g.tsHours.Day07 ?? 0) / th
      }
      return r
    },
    te (s, g) {
      const r = new ResultKPI()
      r.Name = teName
      r.Day01 = 0
      r.Day02 = 0
      r.Day03 = 0
      r.Day04 = 0
      r.Day05 = 0
      r.Day06 = 0
      r.Day07 = 0
      const th = (s.shop.TechnicianCount ?? 0) * (s.shop.WorkingHoursPerDay ?? 0)
      if (th !== 0) {
        r.Day01 = (g.techHours.Day01 ?? 0) / th
        r.Day02 = (g.techHours.Day02 ?? 0) / th
        r.Day03 = (g.techHours.Day03 ?? 0) / th
        r.Day04 = (g.techHours.Day04 ?? 0) / th
        r.Day05 = (g.techHours.Day05 ?? 0) / th
        r.Day06 = (g.techHours.Day06 ?? 0) / th
        r.Day07 = (g.techHours.Day07 ?? 0) / th
      }
      return r
    },
    techHours (s, g, rs, rg) {
      const r = new ResultKPI()
      r.Name = tsHours
      r.Day01 = rg['timeSheets/day01TechTotal']
      r.Day02 = rg['timeSheets/day02TechTotal']
      r.Day03 = rg['timeSheets/day03TechTotal']
      r.Day04 = rg['timeSheets/day04TechTotal']
      r.Day05 = rg['timeSheets/day05TechTotal']
      r.Day06 = rg['timeSheets/day06TechTotal']
      r.Day07 = rg['timeSheets/day07TechTotal']
      return r
    },
    tsHours (s, g, rs, rg) {
      const r = new ResultKPI()
      r.Name = tsHours
      r.Day01 = rg['timeSheets/day01Total']
      r.Day02 = rg['timeSheets/day02Total']
      r.Day03 = rg['timeSheets/day03Total']
      r.Day04 = rg['timeSheets/day04Total']
      r.Day05 = rg['timeSheets/day05Total']
      r.Day06 = rg['timeSheets/day06Total']
      r.Day07 = rg['timeSheets/day07Total']
      return r
    },
    wd1 (s) { return dayjs(s.dp).weekday(0) },
    wd2 (s) { return dayjs(s.dp).weekday(1) },
    wd3 (s) { return dayjs(s.dp).weekday(2) },
    wd4 (s) { return dayjs(s.dp).weekday(3) },
    wd5 (s) { return dayjs(s.dp).weekday(4) },
    wd6 (s) { return dayjs(s.dp).weekday(5) },
    wd7 (s) { return dayjs(s.dp).weekday(6) }
  },
  mutations: {
    setDP (s, v) { s.dp = v },
    setKPIs (s, v) { s.kpis = v },
    setShop (s, v) { s.shop = v }
  },
  namespaced: true,
  state: () => ({
    dp: new Date().toLocaleString('sv', { timeZoneName: 'short' }).substring(0, 10),
    kpis: [],
    shop: new ShopDto()
  })
}
