import Vue from 'vue'
import Vuex from 'vuex'
import { dashboards } from '@/dashboards/module'
import { kpis } from '@/kpis/module'
import { kpiTargets } from '@/kpi-targets/module'
import { navigation } from '@/navigation/module'
import { notifications } from '@/notificatons/module'
import { profiles } from '@/profiles/module'
import { repairOrders } from '@/repair-orders/model'
import { shops } from '@/shops/module'
import { teams } from '@/team-members/module'
import { timeSheets } from '@/time-sheets/module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    dashboards,
    kpis,
    kpiTargets,
    navigation,
    notifications,
    profiles,
    repairOrders,
    shops,
    teams,
    timeSheets
  }
})
