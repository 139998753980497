<template>
  <div id="app">
    <v-app class="fill-height">
      <v-overlay :value="loading" style="z-index: 300" />
      <router-view v-if="authReady" class="fill-height" />
    </v-app>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapGetters({
      loading: 'notifications/loading'
    }),
    authReady () { return !this.$auth.loading }
  }
}
</script>
