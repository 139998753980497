// eslint-disable-next-line no-unused-vars
import { Dto, Week } from '@/time-sheets/viewModel'
import dayjs from 'dayjs'

/**
 * @description transform daily timesheet rows into one week per employee for the given date
 * @param a {Dto[]}
 * @param d {string} - Iso Date string
 * @returns {Week[]}
 */
export function transformToWeeks (a, d) {
  // create an array of unique employee Ids
  const eIds = [...new Set(a.map((e) => e.EmployeeID))]
  // establish days 1 - 7 in ISO Date String format
  const djs = dayjs(d)
  const wd1 = djs.weekday(0).toISOString().substring(0, 10)
  const wd2 = djs.weekday(1).toISOString().substring(0, 10)
  const wd3 = djs.weekday(2).toISOString().substring(0, 10)
  const wd4 = djs.weekday(3).toISOString().substring(0, 10)
  const wd5 = djs.weekday(4).toISOString().substring(0, 10)
  const wd6 = djs.weekday(5).toISOString().substring(0, 10)
  const wd7 = djs.weekday(6).toISOString().substring(0, 10)

  const r = []
  for (let i = 0; i < eIds.length; i++) {
    const id = eIds[i]
    if (id) {
      const d1 = a.find((e) => e.EmployeeID === id && e.WorkedOn.substring(0, 10) === wd1)
      const d2 = a.find((e) => e.EmployeeID === id && e.WorkedOn.substring(0, 10) === wd2)
      const d3 = a.find((e) => e.EmployeeID === id && e.WorkedOn.substring(0, 10) === wd3)
      const d4 = a.find((e) => e.EmployeeID === id && e.WorkedOn.substring(0, 10) === wd4)
      const d5 = a.find((e) => e.EmployeeID === id && e.WorkedOn.substring(0, 10) === wd5)
      const d6 = a.find((e) => e.EmployeeID === id && e.WorkedOn.substring(0, 10) === wd6)
      const d7 = a.find((e) => e.EmployeeID === id && e.WorkedOn.substring(0, 10) === wd7)
      if (d1 && d2 && d3 && d4 && d5 && d6 && d7) {
        r.push({
          EmployeeID: id,
          FullName: d1.FullName,
          Day01: d1.WorkedOn,
          Day01ID: d1.ID,
          Day01Hours: d1.Hours,
          Day02: d2.WorkedOn,
          Day02ID: d2.ID,
          Day02Hours: d2.Hours,
          Day03: d3.WorkedOn,
          Day03ID: d3.ID,
          Day03Hours: d3.Hours,
          Day04: d4.WorkedOn,
          Day04ID: d4.ID,
          Day04Hours: d4.Hours,
          Day05: d5.WorkedOn,
          Day05ID: d5.ID,
          Day05Hours: d5.Hours,
          Day06: d6.WorkedOn,
          Day06ID: d6.ID,
          Day06Hours: d6.Hours,
          Day07: d7.WorkedOn,
          Day07ID: d7.ID,
          Day07Hours: d7.Hours,
          ShopID: d1.ShopID,
          Technician: d1.Technician
        })
      }
    }
  }
  return r
}
