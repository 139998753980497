import { valueFromKey } from '@/utils'

export const inCents = 'Currency'
export const inPercent = 'Percentage'

export class Dto {
  /** @type {string} - UUID */
  ID

  /** @type {string} - UUID */
  KpiTargetID

  /** @type {string} - UUID */
  ShopID

  /** @type {number} - Currency in cents or whole number percentage determined by type */
  High

  /** @type {number} - Currency in cents or whole number percentage determined by type */
  Low

  /** @type {number} - Currency in cents or whole number percentage determined by type */
  Medium

  /** @type {"Currency" | "Percentage"} */
  Type

  constructor () {
    this.ID = ''
    this.KpiTargetID = ''
    this.ShopID = ''
    this.High = 0
    this.Low = 0
    this.Medium = 0
    this.Type = 'Currency'
  }

  static fromApi (a) {
    const r = new Dto()
    r.ID = valueFromKey('ID', a)
    r.Name = valueFromKey('Name', a)
    r.KpiTargetID = valueFromKey('KpiTargetID', a)
    r.ShopID = valueFromKey('ShopID', a)
    r.High = valueFromKey('High', a)
    r.Low = valueFromKey('Low', a)
    r.Medium = valueFromKey('Medium', a)
    r.Type = valueFromKey('Type', a)
    return r
  }
}

export class Model {
  /** @type {String} - UUID */
  ID

  /** @type {String} - UUID */
  ShopID

  /** @type {number} - Currency in cents or whole number percentage determined by type */
  High
  /** @type {number} - Currency in cents or whole number percentage determined by type */
  Low

  /** @type {number} - Currency in cents or whole number percentage determined by type */
  Medium

  /** @type {string} */
  Name

  /** @type {"Currency" | "Percentage"} */
  Type

  get highDisplay () {
    let r = this.High
    if (this.Type === inCents) r = (this.High * 0.01).toFixed(2)
    return r
  }

  get lowDisplay () {
    let r = this.Low
    if (this.Type === inCents) r = (this.Low * 0.01).toFixed(2)
    return r
  }

  get mediumDisplay () {
    let r = this.Medium
    if (this.Type === inCents) r = (this.Medium * 0.01).toFixed(2)
    return r
  }

  constructor () {
    this.ID = ''
    this.ShopID = ''
    this.Name = ''
    this.High = 0
    this.Low = 0
    this.Medium = 0
    this.Type = 'Currency'
  }

  /** @param d {Dto} */
  static fromDto (d) {
    const r = new Model()
    r.ID = valueFromKey('ID', d)
    r.ShopID = valueFromKey('ShopID', d)
    r.Name = valueFromKey('Name', d)
    r.High = valueFromKey('High', d)
    r.Low = valueFromKey('Low', d)
    r.Medium = valueFromKey('Medium', d)
    r.Type = valueFromKey('Type', d)
    return r
  }

  toApi () {
    return {
      Id: this.ID,
      High: this.High,
      Low: this.Low,
      Medium: this.Medium
    }
  }
}
