<template>
  <section>
    <LeftNav />
    <v-main class="background fill-height">
      <v-progress-linear :active="loading" class="mt-3" color="accent" height="5" indeterminate />
      <v-alert
        v-model="alert.Active"
        class="ma-0"
        dismissible
        :icon="alert.Icon"
        :type="alert.Type"
      >
        {{ alert.Text }}
      </v-alert>
      <v-snackbar v-model="sb.Active" :color="sb.Color">
        <v-icon left>{{ sb.Icon }}</v-icon>
        {{ sb.Text }}
      </v-snackbar>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { icons, text } from '@/constants'

import LeftNav from '@/navigation/LeftNav'

export default {
  name: 'LicensedPage',
  components: { LeftNav },
  data: () => ({
    navDrawerShown: true
  }),
  methods: {
    logout () {
      this.$auth.logout({ returnTo: window.location.origin })
    },
    contactUsClick () { },
    profileClick () { this.$router.push({ name: 'ProfileId' }) }
  },
  computed: {
    ...mapGetters({
      alert: 'notifications/alertConfig',
      loading: 'notifications/loading',
      sb: 'notifications/snackbarConfig'
    }),
    i () { return icons },
    t () { return text }
  }
}
</script>

<style lang="scss" scoped>
.v-list-item--active {
  background-color: var(--v-primary-base);
  font-weight: bold;
}
</style>
