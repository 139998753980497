import { centsToDollars, decimalToPercent, secondsToHours, valueFromKey } from '@/utils'

export const aROName = 'Average Repair Order'
export const billedHours = 'Billed Hours'
export const carCount = 'Car Count'
export const cogsName = 'COGS'
export const eLRName = 'Effective Labor Rate'
export const grossProfit = 'Gross Profit'
export const gpPerBilled = 'Profit Per Hour Billed'
export const gpPerTSName = 'Profit Per Labor Hour'
export const gsPerBilled = 'Sales Per Hour Billed'
export const laborMargin = 'Labor Margin'
export const laborSales = 'Labor Sales'
export const partCosts = 'Part Costs'
export const partMargin = 'Part Margin'
export const partSales = 'Part Sales'
export const subletCOGS = 'Sublet COGS'
export const tbeName = 'Total Building Efficiency'
export const teName = 'Total Tech Efficiency'
export const totalSales = 'Total Sales'
export const tsHours = 'Time Sheet Hours'

// Most Sales & Result KPIs are currency values; only establish arrays for the exceptions (counts and percentages)
export const resultKpiAsCount = [eLRName, tsHours]
export const resultKpiAsPercentage = [laborMargin, partMargin, tbeName, teName]

export const salesKpiAsCount = [carCount]
export const salesKpiAsHours = [billedHours]

export class ResultKPI {
  /** @type {string} */ Name
  /** @type {number} */ Day01
  /** @type {number} */ Day02
  /** @type {number} */ Day03
  /** @type {number} */ Day04
  /** @type {number} */ Day05
  /** @type {number} */ Day06
  /** @type {number} */ Day07

  get Day01Display () {
    let r = this.Day01
    if (this.DisplayAsDollars) r = centsToDollars(this.Day01)
    if (this.DisplayAsPercentage) r = decimalToPercent(this.Day01)
    return r
  }

  get Day02Display () {
    let r = this.Day02
    if (this.DisplayAsDollars) r = centsToDollars(this.Day02)
    if (this.DisplayAsPercentage) r = decimalToPercent(this.Day02)
    return r
  }

  get Day03Display () {
    let r = this.Day03
    if (this.DisplayAsDollars) r = centsToDollars(this.Day03)
    if (this.DisplayAsPercentage) r = decimalToPercent(this.Day03)
    return r
  }

  get Day04Display () {
    let r = this.Day04
    if (this.DisplayAsDollars) r = centsToDollars(this.Day04)
    if (this.DisplayAsPercentage) r = decimalToPercent(this.Day04)
    return r
  }

  get Day05Display () {
    let r = this.Day05
    if (this.DisplayAsDollars) r = centsToDollars(this.Day05)
    if (this.DisplayAsPercentage) r = decimalToPercent(this.Day05)
    return r
  }

  get Day06Display () {
    let r = this.Day06
    if (this.DisplayAsDollars) r = centsToDollars(this.Day06)
    if (this.DisplayAsPercentage) r = decimalToPercent(this.Day06)
    return r
  }

  get Day07Display () {
    let r = this.Day07
    if (this.DisplayAsDollars) r = centsToDollars(this.Day07)
    if (this.DisplayAsPercentage) r = decimalToPercent(this.Day07)
    return r
  }

  get DisplayAsCount () { return resultKpiAsCount.some((e) => e === this.Name) }
  get DisplayAsDollars () { return !this.DisplayAsPercentage && !this.DisplayAsCount }
  get DisplayAsPercentage () { return resultKpiAsPercentage.some((e) => e === this.Name) }
}

export class SalesKPI {
  /** @type {string} */ Name
  /** @type {number} */ Day01
  /** @type {number} */ Day02
  /** @type {number} */ Day03
  /** @type {number} */ Day04
  /** @type {number} */ Day05
  /** @type {number} */ Day06
  /** @type {number} */ Day07

  get Day01Display () {
    let r = centsToDollars(this.Day01)
    if (this.DisplayAsCount) r = this.Day01
    if (this.DisplayAsHours) r = secondsToHours(this.Day01)
    return r
  }

  get Day02Display () {
    let r = centsToDollars(this.Day02)
    if (this.DisplayAsCount) r = this.Day02
    if (this.DisplayAsHours) r = secondsToHours(this.Day02)
    return r
  }

  get Day03Display () {
    let r = centsToDollars(this.Day03)
    if (this.DisplayAsCount) r = this.Day03
    if (this.DisplayAsHours) r = secondsToHours(this.Day03)
    return r
  }

  get Day04Display () {
    let r = centsToDollars(this.Day04)
    if (this.DisplayAsCount) r = this.Day04
    if (this.DisplayAsHours) r = secondsToHours(this.Day04)
    return r
  }

  get Day05Display () {
    let r = centsToDollars(this.Day05)
    if (this.DisplayAsCount) r = this.Day05
    if (this.DisplayAsHours) r = secondsToHours(this.Day05)
    return r
  }

  get Day06Display () {
    let r = centsToDollars(this.Day06)
    if (this.DisplayAsCount) r = this.Day06
    if (this.DisplayAsHours) r = secondsToHours(this.Day06)
    return r
  }

  get Day07Display () {
    let r = centsToDollars(this.Day07)
    if (this.DisplayAsCount) r = this.Day07
    if (this.DisplayAsHours) r = secondsToHours(this.Day07)
    return r
  }

  get DisplayAsCount () { return salesKpiAsCount.some((e) => e === this.Name) }
  get DisplayAsDollars () { return !this.DisplayAsCount && !this.DisplayAsHours }
  get DisplayAsHours () { return salesKpiAsHours.some((e) => e === this.Name) }

  static fromApi (a) {
    const r = new SalesKPI()
    r.Name = valueFromKey('Name', a)
    r.Day01 = valueFromKey('Day01', a)
    r.Day02 = valueFromKey('Day02', a)
    r.Day03 = valueFromKey('Day03', a)
    r.Day04 = valueFromKey('Day04', a)
    r.Day05 = valueFromKey('Day05', a)
    r.Day06 = valueFromKey('Day06', a)
    r.Day07 = valueFromKey('Day07', a)
    return r
  }
}
