import { valueFromKey } from '@/utils'

export class Dto {
  /** @type{string} */ ID
  /** @type{Date} */ CreatedAt
  /** @type{string} */ CreatedBy
  /** @type{Date} */ UpdatedAt
  /** @type{string} */ UpdatedBy
  /** @type{string} */ Email
  /** @type{string} */ EmployeeID
  /** @type{string} */ FirstName
  /** @type{number} */ Hours
  /** @type{string} */ LastName
  /** @type{string} */ ShopID
  /** @type{string} */ ShopName
  /** @type{bool} */ Technician
  /** @type{string} - ISO Date String */ WorkedOn

  get FullName () { return `${this.FirstName} ${this.LastName}` }

  static fromApi (a) {
    const r = new Dto()
    r.ID = valueFromKey('ID', a)
    r.CreatedAt = valueFromKey('CreatedAt', a)
    r.CreatedBy = valueFromKey('CreatedBy', a)
    r.UpdatedAt = valueFromKey('UpdatedAt', a)
    r.UpdatedBy = valueFromKey('UpdatedBy', a)
    r.EmployeeID = valueFromKey('EmployeeID', a)
    r.FirstName = valueFromKey('FirstName', a)
    r.Hours = valueFromKey('Hours', a)
    r.LastName = valueFromKey('LastName', a)
    r.ShopID = valueFromKey('ShopID', a)
    r.ShopName = valueFromKey('ShopName', a)
    r.Technician = valueFromKey('Technician', a)
    r.WorkedOn = valueFromKey('WorkedOn', a)
    return r
  }
}

export class Model {
  /** @type{String} */ ID
  /** @type{String} */ EmployeeID
  /** @type{Number} */ Hours
  /** @type{String} */ ShopID
  /** @type{String} - ISO Date String */ WorkedOn

  /** @param d {Dto} */
  static fromDto (d) {
    const r = new Model()
    r.ID = valueFromKey('ID', d)
    r.EmployeeID = valueFromKey('EmployeeID', d)
    r.Hours = valueFromKey('Hours', d)
    r.ShopID = valueFromKey('ShopID', d)
    r.WorkedOn = valueFromKey('WorkedOn', d)
    return r
  }

  /** @return {string} */
  toApi () {
    return {
      Hours: this.Hours ?? 0
    }
  }
}

export class Week {
  /** @type{string} */ EmployeeID
  // ***** Day 1 *****
  /** @type{string} - ISO Date String */ Day01
  /** @type{string} */ Day01ID
  /** @type{number} */ Day01Hours

  // ***** Day 2 *****
  /** @type{string} - ISO Date String */ Day02
  /** @type{string} */ Day02ID
  /** @type{number} */ Day02Hours

  // ***** Day 3 *****
  /** @type{string} - ISO Date String */ Day03
  /** @type{string} */ Day03ID
  /** @type{number} */ Day03Hours

  // ***** Day 4 *****
  /** @type{string} - ISO Date String */ Day04
  /** @type{string} */ Day04ID
  /** @type{number} */ Day04Hours

  // ***** Day 5 *****
  /** @type{string} - ISO Date String */ Day05
  /** @type{string} */ Day05ID
  /** @type{number} */ Day05Hours

  // ***** Day 6 *****
  /** @type{string} - ISO Date String */ Day06
  /** @type{string} */ Day06ID
  /** @type{number} */ Day06Hours

  // ***** Day 7 *****
  /** @type{string} - ISO Date String */ Day07
  /** @type{string} */ Day07ID
  /** @type{number} */ Day07Hours

  /** @type{string} */ ShopID
  /** @type{bool} */ Technician
}
