import Vue from 'vue'
import App from './App.vue'
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import highchartsMore from 'highcharts/highcharts-more'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'

import { Auth0Plugin, useAuth0 } from '@/auth/auth0-plugin'
import { centsToDollars, formatDate, formatPhone } from '@/utils'

highchartsMore(Highcharts)

Vue.use(
  Auth0Plugin,
  {
    audience: process.env.VUE_APP_AUTH_AUDIENCE_SHOP_API,
    domain: process.env.VUE_APP_AUTH_DOMAIN,
    clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
    onRedirectCallback: useAuth0.onRedirectCallback
  },
  HighchartsVue
)

Vue.config.productionTip = false

Vue.filter('date', formatDate)
Vue.filter('dollars', centsToDollars)
Vue.filter('phone', formatPhone)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
