import { api } from '@/api'
import { Dto, Model } from '@/kpi-targets/viewModel'
import { valueFromKey } from '@/utils'

const resource = 'KPI-Targets'

export const kpiTargets = {
  actions: {
    /**
     * @param args {Object} - Vuex actions can only support one parameter
     * @param args.shopId {string} - Shop UUID
     * @param args.tok {string} - Access Token
     * @param context {Object} - Module instance injected by Vuex
     */
    async fetchList (context, args) {
      const shopId = valueFromKey('shopId', args)
      const tok = valueFromKey('tok', args)
      const r = await api.get(`/Shops/${shopId}/${resource}`, tok)
      const d = r.map((e) => Dto.fromApi(e))
      const m = d.map((e) => Model.fromDto(e))
      context.commit('SetDl', d)
      context.commit('SetMl', m)
    },
    /**
     * @param args {object} - { id, tok}
     * @param args.m {Model} - Time Sheet Model
     * @param args.tok {string} - Access Token
     * @param context {Object} - module instance injected by Vuex
     * @returns {Promise<void>}
     */
    async update (context, args) {
      const m = valueFromKey('m', args)
      const tok = valueFromKey('tok', args)
      await api.put(`/${resource}/${m.ID}`, m.toApi(), tok)
    }
  },
  getters: {
    dl (s) { return s.dl },
    ml (s) { return s.ml }
  },
  mutations: {
    SetDl (state, val) { state.dl = val },
    SetMl (state, val) { state.ml = val }
  },
  namespaced: true,
  state: () => ({
    /** @type Dto[] */
    dl: [],
    /** @type Model[] */
    ml: []
  })
}
