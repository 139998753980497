const light = {
  background: '#f6f6f6',
  onAccent: '#fff',
  onBackground: '#1c2126',
  onPrimary: '#fff',
  onSecondary: '#fff',
  onSurface: '#1c2126',
  primary: '#4a56e2',
  primaryDark: '#1c2126',
  primaryLight: '#fff',
  secondary: '#24cca7',
  secondaryDark: '#727981',
  secondaryLight: '#f6f6f6',
  accent: '#ff8576',
  error: '#ff8576',
  info: '#4a56e2',
  success: '#24cca7',
  surface: '#fff',
  warning: '#FFC107'
}

export default light
