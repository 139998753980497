import { valueFromKey } from '@/utils'

export class ApiDto {
  ID
  Email
  FirstName
  LastName
  constructor () {
    this.ID = null
    this.Email = null
    this.FirstName = null
    this.LastName = null
  }
}

export class ClientDto {
  Email
  ShopID
  constructor () {
    this.Email = null
    this.ShopID = null
  }

  static fromApi (arg) {
    const result = new ClientDto()
    result.Email = valueFromKey('Email', arg)
    return result
  }
}

export const Rules = {
  Email: [
    (v) => !!v || 'E-mail is required',
    (v) => { return /.+@.+\..+/.test(v) || 'E-mail must be valid' }
  ],
  ShopID: [
    (v) => !!v || 'ShopID is required'
  ]
}
