import { Dto } from '@/shops/viewModel'
import { api } from '@/api'
import { valueFromKey } from '@/utils'

export const shops = {
  actions: {
    /**
     * @param tok {string} - Access Token
     * @param context {Object} - module instance injected by Vuex
     * @returns {Promise<void>}
     */
    async fetchList (context, tok) {
      const r = await api.get('/Shops', tok)
      const l = r.map((e) => Dto.fromApi(e))
      context.commit('setList', l)
    },

    /**
     * @param args {Object} - Vuex actions can only support one parameter
     * @param args.id {string} - UUID
     * @param args.tok {string} - Access Token
     * @param context {Object} - Module instance injected by Vuex
     */
    async fetchRow (context, args) {
      const id = valueFromKey('id', args)
      const tok = valueFromKey('tok', args)
      const r = await api.get(`/Shops/${id}`, tok)
      const d = Dto.fromApi(r)
      context.commit('setDto', d)
    },
    /**
     * @param args {Object} - Vuex actions only support one parameter
     * @param args.model {Model} - Payload
     * @param args.id {string} - UUID
     * @param args.tok {string} - Access Token
     * @param context
     * @returns {Promise<void>}
     */
    async put (context, args) {
      const m = valueFromKey('model', args)
      const id = valueFromKey('id', args)
      const tok = valueFromKey('tok', args)
      const r = await api.put(`/Shops/${id}`, m, tok)
      const d = Dto.fromApi(r)
      context.commit('setDto', d)
    }
  },
  getters: {
    dto (s) { return s.dto },
    list (s) { return s.list }
  },
  mutations: {
    setList (state, val) { state.list = val },
    setDto (state, val) { state.dto = val }
  },
  namespaced: true,
  state: () => ({
    /** @type Dto */ dto: new Dto(),
    /** @type Dto[] */ list: []
  })
}
