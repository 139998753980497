/**
 * @property {boolean} Active display / hide alert
 * @property {string} Icon font awesome 5 icon class e.g. fas fa-check
 * @property {string} Text alert message
 * @property {string} Type 'error' | 'info' | 'success' | 'warning'
 */
export class AlertConfig {
  Active
  Icon
  Text
  Type
  constructor () {
    this.Active = false
    this.Icon = 'fas fa-exclamation'
    this.Text = ''
    this.Type = 'error'
  }
}

/**
 * @property {boolean} Active - display / hide snack bar
 * @property {string} Color - Vuetify Theme color
 * @property {string} Icon - awesome 5 icon class e.g. fas fa-check
 * @property {string} Text - snack bar message
 * @property {number} Timeout - Millisecond delay to auto-dismiss snackbar
 */
export class SnackbarConfig {
  Active
  Color
  Icon
  Text
  Timeout

  constructor () {
    this.Active = false
    this.Color = 'success'
    this.Icon = 'fas fa-check'
    this.Text = ''
    this.Timeout = 5000
  }
}
