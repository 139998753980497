import { ApiDto } from '@/team-members/viewModel'
import { api } from '@/api'
import { valueFromKey } from '@/utils'

export const teams = {
  actions: {
    /**
     * @param args {Object} - Vuex actions can only support one parameter
     * @param args.shopId {string} - Shop UUID
     * @param args.tok {string} - Access Token
     * @param context {Object} - Module instance injected by Vuex
     */
    async fetchList (context, args) {
      const shopId = valueFromKey('shopId', args)
      const tok = valueFromKey('tok', args)
      const apiList = await api.get(`/Shops/${shopId}/Members`, tok)
      context.commit('setApiList', apiList)
    },

    /**
     * @param args {Object} - Vuex actions can only support one parameter
     * @param args.model {ClientDto} - UUID
     * @param args.tok {string} - Access Token
     * @param context {Object} - Module instance injected by Vuex
     */
    async post (context, args) {
      const dto = valueFromKey('dto', args)
      const tok = valueFromKey('tok', args)
      const apiDto = await api.post('/Members', dto, tok)
      context.commit('setApiDto', apiDto)
    }
  },
  getters: {
    apiDto (s) { return s.apiDto },
    apiList (s) { return s.apiList }
  },
  mutations: {
    setApiList (state, val) { state.apiList = val },
    setApiDto (state, val) { state.apiDto = val }
  },
  namespaced: true,
  state: () => ({
    /** @type ApiDto */
    apiDto: new ApiDto(),
    /** @type ApiDto[] */
    apiList: []
  })
}
