import { parseAxiosError } from '@/api'
import { AlertConfig, SnackbarConfig } from '@/notificatons/viewModel'

export const notifications = {
  actions: {
    hideLoading (context) { context.commit('setLoading', false) },
    /**
     * @param e {Error} - Error will be parsed and sanitized in prod mode
     * @param context {any} - Module instance injected by Vuex
     */
    showErrorAlert (context, e) {
      const text = parseAxiosError(e)
      context.commit('setAlertConfig', {
        Active: true,
        Icon: 'fas fa-exclamation',
        Text: text,
        Type: 'error'
      })
    },
    /**
     * @param text {string} - Text will be displayed directly to the EU
     * @param context {any} - Module instance injected by Vuex
     */
    showErrorSb (context, text) {
      context.commit('setSnackbarConfig', {
        Active: true,
        Color: 'error',
        Icon: 'fas fa-exclamation',
        Text: text,
        Timeout: 5000
      })
    },
    showLoading (context) { context.commit('setLoading', true) },
    /**
     * @param text {string} - Message to display
     * @param context {any} - Module instance injected by Vuex
     */
    showSuccessSb (context, text) {
      context.commit('setSnackbarConfig', {
        Active: true,
        Color: 'success',
        Icon: 'fas fa-check',
        Text: text,
        Timeout: 5000
      })
    }
  },
  getters: {
    alertConfig (s) { return s.alertConfig },
    loading (s) { return s.loading },
    snackbarConfig (s) { return s.snackbarConfig }
  },
  mutations: {
    setAlertConfig (state, val) { state.alertConfig = val },
    setLoading (state, val) { state.loading = val },
    setSnackbarConfig (state, val) { state.snackbarConfig = val }
  },
  namespaced: true,
  state: () => ({
    loading: false,
    alertConfig: new AlertConfig(),
    snackbarConfig: new SnackbarConfig()
  })
}
