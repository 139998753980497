<script>
import { mapActions, mapGetters } from 'vuex'
import { icons, text } from '@/constants'

export default {
  name: 'LeftNav',
  methods: {
    ...mapActions({
      toggleActive: 'navigation/toggleLeftActive',
      toggleExpanded: 'navigation/toggleLeftExpanded'
    }),
    logout () {
      this.$auth.logout({ returnTo: window.location.origin })
    }
  },
  computed: {
    ...mapGetters({
      active: 'navigation/leftActive',
      expanded: 'navigation/leftExpanded'
    }),
    i () {
      return icons
    },
    t () {
      return text
    }
  }
}
</script>

<template>
  <div>
    <v-navigation-drawer
      v-if="expanded"
      app
      class="pa-2"
      color="background"
      round="lg"
      width="350"
    >
      <v-list class="fill-height rounded-lg" color="primaryDark" dark>
        <v-list-item style="min-height: 68px; text-align: center">
          <v-list-item-avatar rounded>
            <v-img src="../assets/avatar.svg" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="onPrimary--text text-h6">
              {{ t.appName }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon small @click="toggleExpanded">
              <v-icon size="medium">{{ i.left }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider class="onPrimary" />
        <v-list-item :to="{ name: 'KPIsList' }" link>
          <v-list-item-icon>
            <v-icon color="onPrimary">{{ i.kpis }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="onPrimary--text">
              {{ t.kpis }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'DashboardList' }" link>
          <v-list-item-icon>
            <v-icon color="onPrimary">{{ i.dashboard }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="onPrimary--text">
              {{ t.dashboards }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'ShopList' }" link>
          <v-list-item-icon>
            <v-icon color="onPrimary">{{ i.shop }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="onPrimary--text"
              >{{ t.shops }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-12 onPrimary" />
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon color="onPrimary">{{ i.signOut }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="onPrimary--text">
              {{ t.signOut }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-else
      app
      class="pa-2"
      color="background"
      dark
      round="lg"
      width="75"
    >
      <v-list class="fill-height rounded-lg" color="primaryDark" dark>
        <v-list-item style="min-height: 68px; text-align: center">
          <v-btn icon small @click="toggleExpanded">
            <v-icon size="medium">{{ i.right }}</v-icon>
          </v-btn>
        </v-list-item>
        <v-divider class="onPrimary" />
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              :to="{ name: 'KPIsList' }"
              link
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="onPrimary">
                {{ i.kpis }}
              </v-icon>
            </v-list-item>
          </template>
          <span>{{ t.kpis }}</span>
        </v-tooltip>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              :to="{ name: 'DashboardList' }"
              link
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="onPrimary">{{ i.dashboard }}</v-icon>
            </v-list-item>
          </template>
          <span>{{ t.dashboards }}</span>
        </v-tooltip>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              :to="{ name: 'ShopList' }"
              link
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="onPrimary">{{ i.shop }}</v-icon>
            </v-list-item>
          </template>
          <span>{{ t.shops }}</span>
        </v-tooltip>
        <v-divider class="onPrimary" />
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" @click="logout" v-on="on">
              <v-icon color="onPrimary">fas fa-sign-out-alt</v-icon>
            </v-list-item>
          </template>
          <span>{{ t.signOut }}</span>
        </v-tooltip>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<style scoped></style>
