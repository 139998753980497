import { parseDate, valueFromKey } from '@/utils'

export class Dto {
  /** @type {string} */ ID
  /** @type {string} */ ExternalID
  /** @type {string} */ CustomerID
  /** @type {string} */ VehicleID
  /** @type {Date} */ CreatedAt
  /** @type {Date} */ UpdatedAt
  /** @type {number} */ Total
  /** @type {string} */ Type
  /** @type {string} */ ServiceAdvisorExternalID
  /** @type {string} */ Status
  /** @type {number} */ AmountPaid
  /** @type {number} */ AppointmentID
  /** @type {Date} */ CompletedDate
  /** @type {Date} */ CustomerTimeOut
  /** @type {number} */ DiscountTotal
  /** @type {Date} */ EstimateShareDate
  /** @type {string} */ EstimateURL
  /** @type {number} */ FeeTotal
  /** @type {Date} */ InspectionShareDate
  /** @type {string} */ InspectionURL
  /** @type {string} */ InvoiceURL
  /** @type {string} */ Keytag
  /** @type {string} */ Label
  /** @type {number} */ LaborSales
  /** @type {string} */ LeadSource
  /** @type {number} */ MilesIn
  /** @type {number} */ MilesOut
  /** @type {number} */ PartSales
  /** @type {Date} */ PostedDate
  /** @type {number} */ RepairOrderNumber
  /** @type {number} */ SubletSales
  /** @type {number} */ Taxes
  /** @type {number} */ TechnicianID
  /** @type {number} */ TotalSales

  get Day () { return this.CompletedDate.getDay() }
  get Month () { return this.CompletedDate.getMonth() }
  get Year () { return this.CompletedDate.getFullYear() }

  static fromApi (a) {
    const r = new Dto()
    r.ID = valueFromKey('ID', a)
    r.ExternalID = valueFromKey('ExternalID', a)
    r.CustomerID = valueFromKey('CustomerID', a)
    r.VehicleID = valueFromKey('VehicleID', a)
    r.CreatedAt = parseDate(valueFromKey('CreatedAt', a))
    r.UpdatedAt = parseDate(valueFromKey('UpdatedAt', a))
    r.Total = valueFromKey('Total', a)
    r.Type = valueFromKey('Type', a)
    r.ServiceAdvisorExternalID = valueFromKey('ServiceAdvisorExternalID', a)
    r.Status = valueFromKey('Status', a)
    r.AmountPaid = valueFromKey('AmountPaid', a)
    r.AppointmentID = valueFromKey('AppointmentID', a)
    r.CompletedDate = parseDate(valueFromKey('CompletedDate', a))
    r.CustomerTimeOut = parseDate(valueFromKey('CustomerTimeOut', a))
    r.DiscountTotal = valueFromKey('DiscountTotal', a)
    r.EstimateShareDate = parseDate(valueFromKey('EstimateShareDate', a))
    r.EstimateURL = valueFromKey('EstimateURL', a)
    r.FeeTotal = valueFromKey('FeeTotal', a)
    r.InspectionShareDate = parseDate(valueFromKey('InspectionShareDate', a))
    r.InspectionURL = valueFromKey('InspectionURL', a)
    r.InvoiceURL = valueFromKey('InvoiceURL', a)
    r.Keytag = valueFromKey('Keytag', a)
    r.Label = valueFromKey('Label', a)
    r.LaborSales = valueFromKey('LaborSales', a)
    r.LeadSource = valueFromKey('LeadSource', a)
    r.MilesIn = valueFromKey('MilesIn', a)
    r.MilesOut = valueFromKey('MilesOut', a)
    r.PartSales = valueFromKey('PartSales', a)
    r.PostedDate = parseDate(valueFromKey('PostedDate', a))
    r.RepairOrderNumber = valueFromKey('RepairOrderNumber', a)
    r.SubletSales = valueFromKey('SubletSales', a)
    r.Taxes = valueFromKey('Taxes', a)
    r.TechnicianID = valueFromKey('TechnicianID', a)
    r.TotalSales = valueFromKey('TotalSales', a)
    return r
  }
}
