import { valueFromKey } from '@/utils'

export class Dto {
  /** @type{string} **/ ID
  /** @type{Date} **/ CreatedAt
  /** @type{string} **/ CreatedBy
  /** @type{Date} **/ UpdatedAt
  /** @type{string} **/ UpdatedBy

  /** @type{string} **/ Address
  /** @type{number} **/ Bays
  /** @type{string} **/ City
  /** @type{string} **/ CrossStreets
  /** @type{string} **/ Email
  /** @type{number} **/ EmployeeCostPercent
  /** @type{string} **/ ExternalID
  /** @type{number} **/ HourlyRate
  /** @type{number} **/ LaborCostWeekly
  /** @type{string} **/ LogoURL
  /** @type{number} **/ MonthlyExpensesNonSalary
  /** @type{string} **/ Name
  /** @type{number} **/ PartsMixPercent
  /** @type{string} **/ Phone
  /** @type{string} **/ PostalCode
  /** @type{string} **/ State
  /** @type{number} **/ TechnicianCount
  /** @type{string} **/ Warranty
  /** @type{string} **/ WebURL
  /** @type{number} **/ WorkingDays
  /** @type{number} **/ WorkingHoursPerDay

  // Initialize all properties to avoid valueFromKey exceptions when Vue initially loads
  constructor () {
    this.Address = ''
    this.Bays = 0
    this.City = ''
    this.CreatedAt = null
    this.CreatedBy = ''
    this.UpdatedAt = null
    this.UpdatedBy = ''
    this.CrossStreets = ''
    this.Email = ''
    this.EmployeeCostPercent = 0
    this.ExternalID = ''
    this.HourlyRate = 0
    this.ID = ''
    this.LaborCostWeekly = 0
    this.LogoURL = ''
    this.MonthlyExpensesNonSalary = 0
    this.Name = ''
    this.PartsMixPercent = 0
    this.Phone = ''
    this.PostalCode = ''
    this.State = ''
    this.TechnicianCount = 0
    this.Warranty = ''
    this.WebURL = ''
    this.WorkingDays = 0
    this.WorkingHoursPerDay = 0
  }

  static fromApi (a) {
    const r = new Dto()
    r.ID = valueFromKey('ID', a)
    r.CreatedAt = valueFromKey('CreatedAt', a)
    r.CreatedBy = valueFromKey('CreatedBy', a)
    r.UpdatedAt = valueFromKey('UpdatedAt', a)
    r.UpdatedBy = valueFromKey('UpdatedBy', a)

    r.Address = valueFromKey('Address', a)
    r.Bays = valueFromKey('Bays', a)
    r.City = valueFromKey('City', a)
    r.CrossStreets = valueFromKey('CrossStreets', a)
    r.Email = valueFromKey('Email', a)
    r.EmployeeCostPercent = valueFromKey('EmployeeCostPercent', a)
    r.ExternalID = valueFromKey('ExternalID', a)
    r.HourlyRate = valueFromKey('HourlyRate', a)
    r.LaborCostWeekly = valueFromKey('LaborCostWeekly', a)
    r.LogoURL = valueFromKey('LogoURL', a)
    r.MonthlyExpensesNonSalary = valueFromKey('MonthlyExpensesNonSalary', a)
    r.Name = valueFromKey('Name', a)
    r.PartsMixPercent = valueFromKey('PartsMixPercent', a)
    r.Phone = valueFromKey('Phone', a)
    r.PostalCode = valueFromKey('PostalCode', a)
    r.State = valueFromKey('State', a)
    r.TechnicianCount = valueFromKey('TechnicianCount', a)
    r.Warranty = valueFromKey('Warranty', a)
    r.WebURL = valueFromKey('WebURL', a)
    r.WorkingDays = valueFromKey('WorkingDays', a)
    r.WorkingHoursPerDay = valueFromKey('WorkingHoursPerDay', a)
    return r
  }
}

export class Model {
  /** @type{string} **/ Address
  /** @type{number} **/ Bays
  /** @type{string} **/ City
  /** @type{string} **/ CrossStreets
  /** @type{string} **/ Email
  /** @type{number} **/ EmployeeCostPercent
  /** @type{string} **/ ExternalID
  /** @type{number} **/ HourlyRate
  /** @type{number} **/ LaborCostWeekly
  /** @type{string} **/ LogoURL
  /** @type{number} **/ MonthlyExpensesNonSalary
  /** @type{string} **/ Name
  /** @type{number} **/ PartsMixPercent
  /** @type{string} **/ Phone
  /** @type{string} **/ PostalCode
  /** @type{string} **/ State
  /** @type{number} **/ TechnicianCount
  /** @type{string} **/ Warranty
  /** @type{string} **/ WebURL
  /** @type{number} **/ WorkingDays
  /** @type{number} **/ WorkingHoursPerDay

  /** @param d {Dto} */
  static fromDto (d) {
    const r = new Model()
    r.Address = valueFromKey('Address', d)
    r.Bays = valueFromKey('Bays', d)
    r.City = valueFromKey('City', d)
    r.CrossStreets = valueFromKey('CrossStreets', d)
    r.Email = valueFromKey('Email', d)
    r.EmployeeCostPercent = valueFromKey('EmployeeCostPercent', d)
    r.ExternalID = valueFromKey('ExternalID', d)
    r.HourlyRate = valueFromKey('HourlyRate', d)
    r.LaborCostWeekly = valueFromKey('LaborCostWeekly', d)
    r.LogoURL = valueFromKey('LogoURL', d)
    r.MonthlyExpensesNonSalary = valueFromKey('MonthlyExpensesNonSalary', d)
    r.Name = valueFromKey('Name', d)
    r.PartsMixPercent = valueFromKey('PartsMixPercent', d)
    r.Phone = valueFromKey('Phone', d)
    r.PostalCode = valueFromKey('PostalCode', d)
    r.State = valueFromKey('State', d)
    r.TechnicianCount = valueFromKey('TechnicianCount', d)
    r.Warranty = valueFromKey('Warranty', d)
    r.WebURL = valueFromKey('WebURL', d)
    r.WorkingDays = valueFromKey('WorkingDays', d)
    r.WorkingHoursPerDay = valueFromKey('WorkingHoursPerDay', d)
    return r
  }
}

export const Rules = {
  Email: [(v) => {
    if (!v) return true
    return /.+@.+\..+/.test(v) || 'E-mail must be valid'
  }],
  Name: [
    (v) => !!v || 'Name is required',
    (v) => { return (v && v.length >= 3) || 'Name must be at least 3 characters' }
  ],
  Phone: [(v) => !!v || 'Phone is required']
}
