import { valueFromKey } from '@/utils'

export class Dto {
  FirstName
  LastName
  LocationCount
  PhoneNumber
  Position
  ShopName
  ShopURL

  constructor () {
    this.FirstName = null
    this.LastName = null
    this.LocationCount = null
    this.PhoneNumber = null
    this.Position = null
    this.ShopName = null
    this.ShopURL = null
  }

  static fromApi (arg) {
    const result = new Dto()
    result.FirstName = valueFromKey('FirstName', arg)
    result.LastName = valueFromKey('LastName', arg)
    result.LocationCount = valueFromKey('LocationCount', arg)
    result.PhoneNumber = valueFromKey('PhoneNumber', arg)
    result.Position = valueFromKey('Position', arg)
    result.ShopName = valueFromKey('ShopName', arg)
    result.ShopURL = valueFromKey('ShopURL', arg)
    return result
  }
}

// Validation Rules

export const Rules = {
  FirstName: [
    (v) => !!v || 'First Name is required',
    (v) => {
      return (v && v.length >= 3) || 'First Name must be at least 3 characters'
    }
  ],
  LastName: [
    (v) => !!v || 'Last Name is required',
    (v) => {
      return (v && v.length >= 3) || 'Last Name must be at least 3 characters'
    }
  ],
  LocationCount: [
    (v) => !!v || 'Number of locations is required',
    (v) => {
      return (v && parseInt(v) > 0) || 'Number of locations is required'
    }
  ],
  Phone: [(v) => !!v || 'Phone is required'],
  Position: [(v) => !!v || 'Position is required'],
  ShopName: [
    (v) => !!v || 'Shop Name is required',
    (v) => {
      return (v && v.length >= 3) || 'Shop Name must be at least 3 characters'
    }
  ],
  ShopUrl: [(v) => !!v || 'Shop Website is required']
}
