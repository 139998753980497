import { api } from '@/api'
import { Dto, Model } from '@/time-sheets/viewModel'
import { valueFromKey } from '@/utils'
import { transformToWeeks } from '@/time-sheets/service'

const resource = 'TimeSheets'

export const timeSheets = {
  actions: {
    /**
     * @param args {Object}
     * @param args.end {string} - End ISO Date string
     * @param args.id {string} - ShopDto ID
     * @param args.start {string} - Start ISO Date string
     * @param args.tok {string} - Access Token
     * @param context {Object} - module instance injected by Vuex
     * @returns {Promise<void>}
     */
    async byShop (context, args) {
      const id = valueFromKey('id', args)
      const end = valueFromKey('end', args)
      const start = valueFromKey('start', args)
      const tok = valueFromKey('tok', args)
      // init ts data
      await api.post(`/Shops/${id}/${resource}`, {}, tok, { end, start })
      const r = await api.get(`/Shops/${id}/${resource}`, tok, { end, start })
      const d = r.map((e) => Dto.fromApi(e))
      const m = d.map((e) => Model.fromDto(e))
      const w = transformToWeeks(d, start)
      context.commit('setModels', m)
      context.commit('setWeeks', w)
    },
    /**
     * @param args {object} - { id, tok}
     * @param args.m {Model} - Time Sheet Model
     * @param args.tok {string} - Access Token
     * @param context {Object} - module instance injected by Vuex
     * @returns {Promise<void>}
     */
    async update (context, args) {
      const m = valueFromKey('m', args)
      const tok = valueFromKey('tok', args)
      await api.put(`/${resource}/${m.ID}`, m.toApi(), tok)
    }
  },
  getters: {
    day01TechTotal (s) {
      const w = s.weeks.filter((e) => e.Technician) ?? []
      const h = w.map((e) => e.Day01Hours)
      let r = 0
      if (h.length > 0) r = h.reduce((a, b) => a + b)
      return r
    },
    day01Total (s) {
      const h = s.weeks.map((e) => e.Day01Hours)
      let r = 0
      if (h.length > 0) r = h.reduce((a, b) => a + b)
      return r
    },
    day02TechTotal (s) {
      const w = s.weeks.filter((e) => e.Technician) ?? []
      const h = w.map((e) => e.Day02Hours)
      let r = 0
      if (h.length > 0) r = h.reduce((a, b) => a + b)
      return r
    },
    day02Total (s) {
      const h = s.weeks.map((e) => e.Day02Hours)
      let r = 0
      if (h.length > 0) r = h.reduce((a, b) => a + b)
      return r
    },
    day03TechTotal (s) {
      const w = s.weeks.filter((e) => e.Technician) ?? []
      const h = w.map((e) => e.Day03Hours)
      let r = 0
      if (h.length > 0) r = h.reduce((a, b) => a + b)
      return r
    },
    day03Total (s) {
      const h = s.weeks.map((e) => e.Day03Hours)
      let r = 0
      if (h.length > 0) r = h.reduce((a, b) => a + b)
      return r
    },
    day04TechTotal (s) {
      const w = s.weeks.filter((e) => e.Technician) ?? []
      const h = w.map((e) => e.Day04Hours)
      let r = 0
      if (h.length > 0) r = h.reduce((a, b) => a + b)
      return r
    },
    day04Total (s) {
      const h = s.weeks.map((e) => e.Day04Hours)
      let r = 0
      if (h.length > 0) r = h.reduce((a, b) => a + b)
      return r
    },
    day05TechTotal (s) {
      const w = s.weeks.filter((e) => e.Technician) ?? []
      const h = w.map((e) => e.Day05Hours)
      let r = 0
      if (h.length > 0) r = h.reduce((a, b) => a + b)
      return r
    },
    day05Total (s) {
      const h = s.weeks.map((e) => e.Day05Hours)
      let r = 0
      if (h.length > 0) r = h.reduce((a, b) => a + b)
      return r
    },
    day06TechTotal (s) {
      const w = s.weeks.filter((e) => e.Technician) ?? []
      const h = w.map((e) => e.Day06Hours)
      let r = 0
      if (h.length > 0) r = h.reduce((a, b) => a + b)
      return r
    },
    day06Total (s) {
      const h = s.weeks.map((e) => e.Day06Hours)
      let r = 0
      if (h.length > 0) r = h.reduce((a, b) => a + b)
      return r
    },
    day07TechTotal (s) {
      const w = s.weeks.filter((e) => e.Technician) ?? []
      const h = w.map((e) => e.Day07Hours)
      let r = 0
      if (h.length > 0) r = h.reduce((a, b) => a + b)
      return r
    },
    day07Total (s) {
      const h = s.weeks.map((e) => e.Day07Hours)
      let r = 0
      if (h.length > 0) r = h.reduce((a, b) => a + b)
      return r
    },
    models (s) { return s.models },
    weeks (s) { return s.weeks }
  },
  mutations: {
    setModels (state, val) { state.models = val },
    setWeeks (state, val) { state.weeks = val }
  },
  namespaced: true,
  state: () => ({
    /** @type Model[] */ models: [],
    /** @type Week[] */ weeks: []
  })
}
