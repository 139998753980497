import { api } from '@/api'
import { Dto } from '@/repair-orders/viewModel'
import { valueFromKey } from '@/utils'

const resource = 'RepairOrders'

export const repairOrders = {
  actions: {
    /**
     * @param args {object} - {end, id, start, tok}
     * @param args.end {string} - ISO Date
     * @param args.id {string} - Shop ID
     * @param args.start {string} - ISO Date
     * @param args.tok {string} - Access Token
     * @param context {Object} - module instance injected by Vuex
     * @returns {Promise<void>}
     */
    async byShop (context, args) {
      const end = valueFromKey('end', args)
      const id = valueFromKey('id', args)
      const tok = valueFromKey('tok', args)
      const start = valueFromKey('start', args)
      const r = await api.get(`/Shops/${id}/${resource}`, tok, { end, start })
      const d = r.map((e) => Dto.fromApi(e))
      context.commit('setDtos', d)
    }
  },
  getters: {
    dtos (s) { return s.dtos }
  },
  mutations: {
    setDtos (state, val) { state.dtos = val }
  },
  namespaced: true,
  state: () => ({
    /** @type Dto[] */
    dtos: []
  })
}
