import { Dto } from '@/profiles/viewModel'
import { api } from '@/api'
import { valueFromKey } from '@/utils'

export const profiles = {
  actions: {
    /**
     * @param tok {string} Access Token
     * @param context {any} Module instance injected by vuex
     * @returns {Promise<void>}
     * */
    async fetch (context, tok) {
      const apiDto = await api.get('/Profile', tok)
      context.commit('setApiDto', apiDto)
    },
    /**
     * @param args {Object} - Vuex actions can only support one parameter
     * @param args.model {ClientDto} - Payload
     * @param args.tok {string} - Access Token
     * @param context {Object} - Module instance injected by Vuex
     */
    async put (context, args) {
      const dto = valueFromKey('dto', args)
      const tok = valueFromKey('tok', args)
      const apiDto = await api.put('/Profile', dto, tok)
      context.commit('setApiDto', apiDto)
    }
  },
  getters: {
    apiDto: (state) => { return state.apiDto }
  },
  mutations: {
    setApiDto (state, val) { state.apiDto = val }
  },
  namespaced: true,
  state: () => ({
    apiDto: new Dto()
  })
}
