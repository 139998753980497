export const navigation = {
  actions: {
    async reset (context) {
      context.commit('setIndex', null)
      context.commit('setIdList', [])
      context.commit('setReturn', null)
    },
    /**
     * @param v {number}
     * @param context {Object} - module instance injected by Vuex
     */
    async setIndex (context, v) {
      context.commit('setIndex', v)
    },
    /**
     * @param a {string[]}
     * @param context {Object} - module instance injected by Vuex
     */
    async setIdList (context, a) {
      context.commit('setIdList', a)
    },
    /**
     * @param r {Object} - Route
     * @param r.name {string} - Route Name
     * @param context {Object} - module instance injected by Vuex
     */
    async setReturn (context, r) {
      context.commit('setReturn', r)
    },
    toggleLeftActive (context) {
      context.commit('setLeftActive', !context.state.leftActive)
    },
    toggleLeftExpanded (context) {
      context.commit('setLeftExpanded', !context.state.leftExpanded)
    }
  },
  getters: {
    index (s) {
      return s.index
    },
    leftActive (s) {
      return s.leftActive
    },
    leftExpanded (s) {
      return s.leftExpanded
    },
    nextId (s) {
      let r = null
      const ni = s.index + 1
      if (ni < s.idList.length) r = s.idList[ni]
      return r
    },
    returnRoute (s) {
      return s.returnRoute
    },
    position (s) {
      if (s.idList.length > 0) return `${s.index + 1} of ${s.idList.length}`
      return null
    }
  },
  mutations: {
    setIndex (s, v) {
      s.index = v
    },
    setIdList (s, v) {
      s.idList = v
    },
    setLeftActive (s, v) {
      s.leftActive = v
    },
    setLeftExpanded (s, v) {
      s.leftExpanded = v
    },
    setReturn (s, v) {
      s.returnRoute = v
    }
  },
  namespaced: true,
  state: () => ({
    leftActive: true,
    leftExpanded: true,
    index: null,
    idList: [],
    returnRoute: null
  })
}
