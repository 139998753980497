// eslint-disable-next-line no-unused-vars
import { api } from '@/api'
import { valueFromKey } from '@/utils'
import { MonthFilter } from '@/dashboards/viewModel'

export const dashboards = {
  actions: {
    async fetch (context, args) {
      const id = valueFromKey('id', args)
      const tok = valueFromKey('tok', args)
      const result = await api.get(`/Shops/${id}/Dashboard`, tok) ?? []
      context.commit('setApiDto', result)
    },
    monthCountOnChange (context, val) { context.commit('setMonthCount', val) },
    setSaFilter (context, v) { context.commit('setSaFilter', v) },
    setSearch (context, v) { context.commit('setSearch', v) }
  },
  getters: {
    actionNeeded (s, g) { return g.filteredList.filter((e) => { return e.ActionNeeded }) },
    apiList (s) { return s.apiList },
    appointments (s, g) { return g.filteredList.filter((e) => { return e.ScheduledAppointment }) },
    avgTotal (s, g) {
      let r = 0
      const rows = g.filteredList.map((e) => e.RepairOrderTotal)
      if (rows.length > 0) {
        const total = rows.reduce(function (a, b) { return a + b }, 0) * 0.01
        const avg = total / rows.length
        r = Math.round(Math.round(avg * 100 + Number.EPSILON) / 100)
      }
      return r
    },
    carCount (s, g) { return g.filteredList.filter((e) => (e.RepairOrderCompletedAt ?? '') !== '').length },
    feedback (s, g) {
      const rows = g.filteredList.filter((e) => { return e.Comments.trim() !== '' && e.Comments.trim() !== 'x' }) ?? []
      return rows.map((e) => {
        return {
          comments: e.Comments,
          date: e.UpdatedAt,
          sentiment: e.Sentiment
        }
      })
    },
    filteredList (s, g) {
      return s.apiList.filter((e) => {
        // Convert ISO String to Date object
        const updatedAt = new Date(e.UpdatedAt)
        if (s.saFilter !== '') {
          return (updatedAt >= g.startDate && e.ServiceAdvisorID === s.saFilter)
        } else {
          return (updatedAt >= g.startDate)
        }
      }) ?? []
    },
    headsUp (s, g) { return g.filteredList.filter((e) => { return e.HeadsUp }) },
    highlight (s, g) { return g.filteredList.filter((e) => { return e.Highlight }) },
    monthCount (s) { return s.monthCount },
    /** @returns {MonthFilter[]} */
    monthFilters (s) {
      const r = []
      const now = new Date()
      for (let i = 0; i < s.monthCount; i++) {
        const mf = new MonthFilter()
        const year = now.getFullYear()
        // id is relative to now, not the month calendar order
        mf.id = now.getMonth() - i
        mf.first = new Date(year, mf.id, 1)
        // get last date of month by going to the 1st of the following month less 1 day i.e. date: 0
        mf.last = new Date(year, mf.id + 1, 0)
        mf.name = mf.first.toLocaleString('default', { month: 'long' })
        r.push(mf)
      }
      // Reverse array to display months in chronological order / left to right
      return r.reverse()
    },
    monthNames (s, g) { return g.monthFilters.map((e) => { return e.name }) },
    negativeFeedback (s, g) { return g.filteredList.filter((e) => { return e.NegativeFeedback }) },
    revenueOpportunity (s, g) { return g.filteredList.filter((e) => { return e.Opportunity }) },
    reviewsCommitted (s, g) { return g.filteredList.filter((e) => { return e.ReviewCommitted }) },
    saFilter (s) { return s.saFilter },
    salesOpportunities (s, g) {
      const c = g.filteredList.filter((e) => { return e.Opportunity }).length
      return (c * g.avgTotal).toFixed(0)
    },
    search (s) { return s.search },
    serviceAdvisors (s, g) {
      const ids = [...new Set(g.apiList.map((e) => e.ServiceAdvisorID))]
      const r = []
      ids.forEach((id) => {
        const d = g.apiList.find((e) => e.ServiceAdvisorID === id)
        r.push({
          text: `${d.ServiceAdvisorFirstName} ${d.ServiceAdvisorLastName}`,
          value: id
        })
      })
      return r
    },
    startDate (s) {
      const r = new Date()
      // reduce month count by one to account for current month
      const num = r.getMonth() - (s.monthCount - 1)
      r.setMonth(num)
      r.setDate(1)
      r.setHours(0, 0, 0, 0)
      return r
    }
  },
  mutations: {
    setApiDto (s, v) { s.apiList = v },
    setMonthCount (s, v) { s.monthCount = v },
    setSaFilter (s, v) { s.saFilter = v },
    setSearch (s, v) { s.search = v }
  },
  namespaced: true,
  state: () => ({
    apiList: [],
    monthCount: 1,
    saFilter: '',
    search: ''
  })
}
